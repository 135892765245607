
import { defineComponent, ref, onUnmounted } from "vue"
import { useI18n } from "vue-i18n"
import RouteTabs from '@/views/Payment/components/RouteTabs.vue'
import { useRoute } from 'vue-router'
import useEventbus from "@/hooks/claim/useEventbus"

export default defineComponent({
  name: 'spotChecking',
  components: {
    RouteTabs
  },
  setup() {
    const { t } = useI18n()
    // const defaultBreadCrumbData = [
    //   {name: t('payment.payment'), path: ''},
    //   {name: t('payment.paymentTracking'), path: ''}
    // ]
    const defaultBreadCrumbData: {name: string; path: string}[] = []
    const breadCrumbData = ref(defaultBreadCrumbData)
    return {
      breadCrumbData
    }
  }
})
